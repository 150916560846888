import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const WarningIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="26" height="25" viewBox="0 0 26 25" fill="none">
      <path
        d="M24.7188 20.0469L14.7344 2.98438C13.9375 1.67188 12.0156 1.67188 11.2656 2.98438L1.23438 20.0469C0.484375 21.3594 1.42188 23 2.96875 23H22.9844C24.5312 23 25.4688 21.3594 24.7188 20.0469ZM11.875 8.375C11.875 7.76562 12.3438 7.25 13 7.25C13.6094 7.25 14.125 7.76562 14.125 8.375V14.375C14.125 15.0312 13.6094 15.5 13 15.5C12.4375 15.5 11.875 15.0312 11.875 14.375V8.375ZM13 20C12.1562 20 11.5 19.3438 11.5 18.5469C11.5 17.75 12.1562 17.0938 13 17.0938C13.7969 17.0938 14.4531 17.75 14.4531 18.5469C14.4531 19.3438 13.7969 20 13 20Z"
        fill="#F58C4B"
      />
    </SvgIcon>
  );
};
